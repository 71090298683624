import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, CampanhaAnuncio } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class CampanhaServico extends BaseService{
    obterTodos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'campanha/obtertodos', pArgs);
    }

    obterPorId(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'campanha/obterPorId', pArgs);
    }

    salvar(campanha : CampanhaAnuncio){
        return this.basePost(environment.baseUrlCRM+'campanha/salvar', campanha);
    }
}