export enum Segmento{
    Varejo = 1,
    Atacado_Distribuidoras = 2,
    Manufatura = 3,
    Servicos = 4,
    Bares_Restaurantes = 5,
    Outro = 6,
    Todos = 7,
    VarejoRoupas = 8,
    Aluguel = 9
}