import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, Lead } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class LeadServico extends BaseService{
    obterTodos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'lead/obtertodos', pArgs);
    }

    obterPorId(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'lead/obterporid', pArgs);
    }

    obterPorFunil(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'lead/obterporfunil', pArgs);
    }

    salvar(lead : Lead){
        return this.basePost(environment.baseUrlCRM+'lead/salvar', lead);
    }

    excluir(lead : Lead){
        return this.basePost(environment.baseUrlCRM+'lead/excluir', lead);
    }
}