import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';
import { Usuario, OutSaidaBaseWebApi, StatusRetorno, BuscarArgs } from '../models';
import { PerfilService, EmpresaService, EventEmitterService, UtilService } from '../services';
import { SyncService } from '../services/syncService.service';
import { Router } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'menubar',
  templateUrl: '../../../../menubar.component.html',
  styleUrls: ['../../../../menubar.component.css']
})

export class MenuBarComponent {
    public logado : boolean = false;
    private usuario : boolean = false;

    public urlPDV : string = environment.urlPDV;
    public urlFinanceiro : string = environment.urlFinanceiro;
    public urlEstoque : string = environment.urlEstoque;
    public urlInicial : string = environment.urlInicial;
    public urlRestaurante : string = environment.urlRestaurante;
    public urlCRM : string = environment.urlCRM;
    public urlRelatorios : string = environment.urlRelatorios;

    private show : boolean = false;
    public user : any = new Usuario(); 
    public recursos : any = new Array();

    constructor(public cookieService:CookieService, private perfilService : PerfilService, private syncService : SyncService, private router : Router){
    }

    ngOnInit(){
        this.user = UtilService.obterUsuario();
        setInterval(()=> this.verificaSessao(), 600000)

        $('.linkCor').click(function(e : any){
            $('.esconder-menu').click();
        });
        this.recursos = UtilService.obterRecursos();
    }

     async verificaSessao(){
        var that = this;
        let ipc = this.cookieService.getObject('ipMeubolso')
        if(ipc != undefined && this.user.Id != undefined){
            let param : BuscarArgs = new BuscarArgs();
            param.UsuarioId = this.user.Id;
            param.Ip = ipc.toString();
            param.Token = this.user.Token;

            EventEmitterService.get('onLoading').emit();            
            this.syncService.verificaSessao(param).subscribe(
            (resp) => {
                if(!resp){
                    swal('Erro','Usuário Logado em Outra Sessão !', 'warning');
                    UtilService.removerUsuario();
                    UtilService.removerRecursos()
                    EventEmitterService.get('offLoading').emit();
                    window.location.href = environment.urlInicial;
                }
                else{
                    if((this.recursos === undefined) || (this.recursos === null) || (this.recursos.length === 0) && (this.user.PerfilId > 0)){
                        this.perfilService.obterPerfil(this.user.Id)
                        .subscribe(
                            (ret : OutSaidaBaseWebApi)=> {
                                if(ret.Status === StatusRetorno.SUCESSO){
                                    let recursos : Array<any> = new Array();
                                    if(ret.Entidade !== undefined && ret.Entidade.Recursos !== undefined && ret.Entidade.Recursos.length > 0){
                                        ret.Entidade.Recursos.forEach(function(o : any){
                                            recursos.push(o.Recurso.NomeRecurso)	
                                        });

                                        that.recursos = recursos;
                                        UtilService.setarRecursos(recursos);
                                    }
                                    else
                                        swal('Usuário sem recursos disponíveis', 'É necessário que recursos sejam liberados ao usuário', 'warning');
                                }
                                else
                                    swal('Usuário sem recursos disponíveis', 'É necessário que recursos sejam liberados ao usuário', 'warning');

                                EventEmitterService.get('offLoading').emit();
                            });
                    }
                    else
                        EventEmitterService.get('offLoading').emit();
                }
            },
            (error) => {
                swal('Erro','', 'warning');
                EventEmitterService.get('offLoading').emit();
            });
        }else{
            EventEmitterService.get('offLoading').emit();
            this.user = UtilService.obterUsuario();

            if((this.user === undefined || this.user === null) && (ipc !== undefined))
                window.location.href = environment.urlInicial;
        }
    }
    
    sair(){
        EventEmitterService.get('sair').emit();
    }

    adm(rotina : string){
        window.location.href = `${environment.urlInicial}${rotina}`;
    }

    pdv(rotina : string){
        window.location.href = `${environment.urlPDV}${rotina}?hash=${this.user.Token}`;
    }

    estoque(rotina : string){
        window.location.href = `${environment.urlEstoque}${rotina}?hash=${this.user.Token}`;
    }

    financeiro(rotina : string){
        window.location.href = `${environment.urlFinanceiro}${rotina}?hash=${this.user.Token}`;
    }

    crm(rotina : string){
        this.router.navigate([`/${rotina}`, {hash : this.user.Token}]);
    }

    cms(rotina : string){
        window.location.href = `${environment.urlCMS}${rotina}?hash=${this.user.Token}`;
    }

    addFoco(elemento : string){
        $('#'+elemento).focusout()
        $('#'+elemento).css({'display':'table'})
        $('#'+elemento).focus()
    }

    mouseOver(elemento : string){
        if($('ul:visible').length > 0){
            $('ul:visible').css({'display':'none'})
        }

        if($('.textoBtn:visible').length > 0)
            $('.textoBtn:visible').css({'display':'none'})
        
        $('.btn-primary:hover > .textoBtn').css({'display':'block'})
        $('#'+elemento).css({'display':'table'});
        var el = elemento;
        if($('#'+elemento).length == 0){
            if($('ul:visible').length > 0){
                $('#btn'+el).focus();
            }
        }
    }

    perdeFoco(elemento : string){
        setTimeout(
            () => {
                $('#'+elemento).css({'display':'none'})
            }
        , 1200);
    }

    showAll(){
        $('ul').css({'display':'block'})
        this.show =true;
    }

    hideAll(){
        $('ul').css({'display':'none'})
        this.show =false;
    }

    esconderMenu(){
        $('menubar').css({'display':'none'});
        $('ul').css({'display':'none'});
        $('.navbar-right').css({'display':'table'});
        this.show =false;
    }
}