import { Segmento } from "./segmento";

export class Empresa{
    public Id : number = 0;
    public Segmento : Segmento = Segmento.VarejoRoupas;
    public Financeiro : boolean = false;
    public Estoque : boolean = false;
    public FluxoCaixa : boolean = false;
    public Vendas : boolean = false;
    public Clientes : boolean = false;
    public EmissaoNF : boolean = false;
}