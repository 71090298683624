import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable()
export class BaseService{

    constructor(private http : HttpClient){
    }

    baseGet(url : string){
        return this.http.get<any>(url);
    }

    baseGetComParam(url : string, param : any){
        return this.http.get<any>(url, { params : param});
    }

    basePost(url: string, obj : any) : Observable<any>{
        return this.http.post(url, obj);
    }

    basePostComToken(url: string, obj : any) : Observable<any>{
        return this.http.post(url, obj)
        .pipe(
            catchError(err => {
                if(err == "401")
                    retry();

                return of(0)
            })
        );
    }
    
}