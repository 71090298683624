import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, PublicoAlvo } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class PublicoAlvoService extends BaseService{
    obterPublicos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'publico/obterTodos', pArgs);
    }

    obterPublicosContem(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'publico/ListarPublicosContem', pArgs);
    }

    salvarPublico(publico : PublicoAlvo){
        return this.basePost(environment.baseUrlCRM+'publico/salvar', publico);
    }
}