import { EmailXListaEmail } from "./emailxlistaemail";
import { Frequencia } from "./frequencia";
import { PublicoAlvo } from "./publicoAlvo";
import { Segmento } from "./segmento";

export class ListaEmail{
    public Nome : string | undefined;
    public EmpresaId : number = 0;
    public Frequencia : Frequencia = Frequencia.Semanal;

    public PublicoAlvoId : number = 0;
    public PublicoAlvo : PublicoAlvo = new PublicoAlvo()

    public Segmento : Segmento = Segmento.VarejoRoupas;
    public Emails :Array<EmailXListaEmail> = new Array();
}