import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { MyCurrencyPipe } from "../pipe";

@Directive({ selector: "[myCurrencyFormatter]" })
export class MyCurrencyFormatterDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: MyCurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value : any) {
    this.el.value = this.currencyPipe.parse(value); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value : any) {
    if(value.length <= 3 && parseFloat(value) > 0){
      value = parseFloat(value).toFixed(2);
    }
    this.el.value = this.currencyPipe.transform(value);
  }

  @HostListener("keyup", ["$event.target.value"])
  onKeyup(value : any) {
    this.el.value = this.currencyPipe.transform(value);
  }
}