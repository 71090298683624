import { Component } from "@angular/core";
import { Usuario, ModeloProcesso, EtapaModelo, OutSaidaBaseWebApi, StatusRetorno } from "../models";
import { CRMService } from "../services/crmService.service";
import swal from "sweetalert2";
import { SyncService } from "../services/syncService.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EventEmitterService, UtilService } from "../services";

@Component({
    moduleId: module.id,
    selector: 'cadastrosmc',
    templateUrl: './cadastrosmc.component.html',
    providers : [CRMService,SyncService]
})
export class SMCCadastroComponent{

    public user : any = new Usuario();
    public modelo : ModeloProcesso = new ModeloProcesso();
    public etapa: EtapaModelo = new EtapaModelo();
    public editando : boolean = false;

    constructor(private crmService : CRMService, private router : Router, private route: ActivatedRoute){
        this.user = UtilService.obterUsuario();
        const routeParams = this.route.snapshot.queryParamMap;
        const id = Number(routeParams.get('Id'));
        if((id !== undefined) && (id > 0))
            this.getById(id);
    }

    getById(id : number){
        EventEmitterService.get('onLoading').emit();
        this.crmService.obterModeloProcesso(id)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => 
            {
                if(ret.Status == StatusRetorno.SUCESSO)
                    this.modelo = ret.Entidade;
                EventEmitterService.get('offLoading').emit();
            },
            (error) => 
            {
                swal('Erro ao Buscar', '', 'error')
                EventEmitterService.get('offLoading').emit();
            }
        );
    }

    salvar(){
        EventEmitterService.get('onLoading').emit();
        this.modelo.EmpresaId = this.user.EmpresaId;
        this.crmService.salvarModeloProcesso(this.modelo)
        .subscribe(
            (ret : OutSaidaBaseWebApi)=> {
                if(ret.Status == StatusRetorno.SUCESSO)
                {
                    this.modelo = new ModeloProcesso();
                    swal('Modelo Salvo Com Sucesso!', '', 'success');
                    EventEmitterService.get('offLoading').emit();
                }
                else
                    swal('Erro ao salvar modelo', ret.MsgErro, 'error');
                this.router.navigate(['/modelo']);
            },
            (error) => 
            {
                swal('Erro ao Salvar Modelo', '', 'error')
                EventEmitterService.get('offLoading').emit();
            }
        );
    }

    adicionarEtapa(){
        if(!this.editando){
            this.modelo.Etapas.push(this.etapa);
        }
        this.etapa = new EtapaModelo();
        this.fecharModal("modalCadastro");
    }

    editarEtapa(item : EtapaModelo){
        this.etapa = item;
        this.editando = true;
        this.abrirModal("modalCadastro");
    }
    
    removerEtapa(idx : number){
        this.modelo.Etapas.splice(idx,1);
    }

    novaEtapa(){
        this.etapa = new EtapaModelo();
        if(this.modelo.Etapas == null){
            this.modelo.Etapas = new Array();
        }

        this.abrirModal("modalCadastro");
    }

    abrirModal(idModal : string){
        EventEmitterService.get('abrirModal').emit(idModal);
    }

    fecharModal(idModal : string){
        EventEmitterService.get('fecharModal').emit(idModal);
    }
}