import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "horaPipe" })
export class HoraPipe implements PipeTransform {

    constructor(){}
    
    transform(value: string): string {
        debugger
        value = value.replace(/(\d{2})(\d)/,"$1:$2").toString();

        if(new RegExp(/([01]?[0-9]|2[0-3]):[0-5][0-9]/g).test(value) == false){
            return '00:01';
        }
        return value;
      }
}