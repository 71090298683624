export class BuscarArgs{
    public DataInicio : Date = new Date();
    public DataFim: Date = new Date();
    public DtInicio : string | undefined;
    public DtFim: string | undefined;
    public EmpresaId : number = 0;
    public CategoriaContaPagarId : number = 0;
    public FornecedorId : number = 0;
    public FormaPagamentoId : number = 0;
    public ContaCorrenteId : number = 0;
    public Busca : string | undefined;
    public BuscaId: number | undefined;
    public Skip : number = 0;
    public Take : number = 0;
    public ProdutoId  : number | undefined;
    public UsuarioId  : number | undefined;
    public EntidadeId  : number | undefined;
    public Token : string | undefined;
    public Ip : string | undefined;
}