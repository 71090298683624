import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, Contato, InCarregarContatosPlanilha } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class ContatoService extends BaseService{
    obterContatos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'contato/ListarContatos', pArgs);
    }
    obterContatosContem(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'contato/ListarContatosContem', pArgs);
    }

    salvarContato(contato : Contato){
        return this.basePost(environment.baseUrlCRM+'contato/SalvarContato', contato);
    }

    carregarContatosPlanilha(pInput : InCarregarContatosPlanilha){
        return this.basePost(environment.baseUrlCRM+'contato/carregarContatosPlanilha', pInput);
    }
}