import { ChangeDetectorRef, Component } from '@angular/core';
import * as $ from 'jquery';
import { EventEmitterService } from './services/eventEmitter.service';
import { CookieService } from 'ngx-cookie';
import { Usuario, OutSaidaBaseWebApi, StatusRetorno, BuscarArgs } from './models';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { SyncService } from './services/syncService.service';
import { Segmento } from './models/segmento';
import { UtilService } from './services/util.service';
import { SweetAlertOptions } from 'sweetalert2';
import { PerfilService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public loading : boolean = false;
  public urlInicial : string = '';
  private sub: any;
  public urlVenda : string = '';
  public user : any = new Usuario();
  public loadingsAtivos : number = 0;
  public textoCarregamento : string = "Carregando ...";

  constructor(private cookieService : CookieService,private router: Router, private syncService: SyncService, private perfilService : PerfilService, private cdref: ChangeDetectorRef){
    EventEmitterService.get('onLoading').subscribe((texto)=> {this.onLoading(texto);});
    EventEmitterService.get('offLoading').subscribe(()=> this.offLoading());
    EventEmitterService.get('abrirModal').subscribe((idModal) => this.abrirModal(idModal))
    EventEmitterService.get('fecharModal').subscribe((idModal) => this.fecharModal(idModal))
    EventEmitterService.get('sair').subscribe(()=> this.sair());
    this.urlInicial = environment.urlInicial;
    this.user = UtilService.obterUsuario();

    if(window.location.href.toLowerCase().indexOf('cadastro') == -1){
      let hash = UtilService.getParameterUrlByName("hash");
      if((hash !== null) && (hash !== undefined)){
        if((this.user !== undefined) && (this.user.Id !== undefined) && (this.user.Id > 0)){
          if(this.user.Token !== hash){
              UtilService.removerUsuario();
              this.criarCookie(hash)
          }
          else{
            this.syncService.obterIP()
            .subscribe((ret)=> this.cookieService.putObject('ipMeubolso', ret.Entidade));
          }

        }else
          this.criarCookie(hash)
      }
      else
        window.location.href = environment.urlInicial;
    }

    // if(this.user != undefined && user.Empresa != undefined){
    //   let segmento : any = Segmento[user.Empresa.Segmento]
    //   if(segmento == Segmento.Bares_Restaurantes){
    //     this.urlVenda = environment.urlRestaurante+'checkout?hash=' + user.Id;
    //   }else if(segmento == Segmento.Servicos){
    //     this.urlVenda = environment.urlPDV+'cadastrarOS?hash=' + user.Id;
    //   }else{
        this.urlVenda = environment.urlPDV+'checkout?hash=' + this.user.Id;
      // }
    // }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  showMenu(){
    $('menubar').css({'display':'table'});
  }

  onLoading(texto : string){
    if(texto !== undefined)
      this.textoCarregamento = texto;
    this.loading = true;
    this.loadingsAtivos++;
  }

  offLoading(){
    if(this.loadingsAtivos > 1)
      this.loadingsAtivos--;
    else{
      this.loadingsAtivos--;
      this.loading = false;
      this.textoCarregamento = '';
    }
  }

  criarCookie(hash : string){
    this.onLoading("Autenticando o usuário nesse módulo ...");
    this.syncService.obterIP()
    .subscribe(
      (ret : OutSaidaBaseWebApi)=> {
        this.cookieService.putObject('ipMeubolso', ret.Entidade)
        var args : BuscarArgs = new BuscarArgs();
        args.Token = hash;
        args.Ip = ret.Entidade;
        
        this.syncService.autenticarPorSessao(args)
        .subscribe(
          (ret)=> {
            if(ret.Status == StatusRetorno.SUCESSO){
              UtilService.setarUsuario(ret.Entidade);
              this.user = ret.Entidade;
              this.perfilService.obterPerfil(this.user.Id)
              .subscribe(
              (ret : OutSaidaBaseWebApi)=> {
                if(ret.Status === StatusRetorno.SUCESSO){
                  let recursos : Array<any> = new Array();
                  if(ret.Entidade !== undefined && ret.Entidade.Recursos !== undefined && ret.Entidade.Recursos.length > 0){
                    ret.Entidade.Recursos.forEach(function(o : any){
                        recursos.push(o.Recurso.NomeRecurso)	
                    });

                    UtilService.setarRecursos(recursos);
                    window.location.reload();
                  }
                  else
                    swal('Usuário sem recursos disponíveis', 'É necessário que recursos sejam liberados ao usuário', 'warning');
                }
                else
                    swal('Usuário sem recursos disponíveis', 'É necessário que recursos sejam liberados ao usuário', 'warning');
              });
              this.offLoading();
            }
            else {
              let options : SweetAlertOptions = {
                title: 'Erro ao autenticar sessão',
                html: `<p>${ret.MsgErro}</p>`,
                showConfirmButton: true,
                confirmButtonText: `OK`
              }
              this.offLoading();
              swal(options).then((result) => {
                this.sair()
              });
            }
        },
        (error) => this.offLoading())
    },
    (error) => this.offLoading())
  }

  sair(){
    UtilService.removerUsuario();
    UtilService.removerRecursos();
    window.location.href = `${environment.urlInicial}?sair=s`;
  }

  abrirModal(idModal : string){
    $('#'+idModal).addClass('in').css({'display': 'block'});
  }

  fecharModal(idModal : string){
    $('#'+idModal).removeClass('in').css({'display': 'none'});
    if($('.modal-backdrop:visible').length > 0)
      $('.modal-backdrop:visible').removeClass('in');
  }
}