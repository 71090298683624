export enum Modulos{
    PDV = 0,
    Estoque = 1,
    Financeiro = 2,
    Administracao = 3,
    RH = 4,
    Manutencao = 5,
    Fiscal = 6,
    Relatorios = 7,
    Usuarios = 8,
    Restaurante = 8
}