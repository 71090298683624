import { PlataformaAnuncio } from "./plataformaAnuncio";
import { CategoriaAnuncio } from "./categoriaanuncio";
import { PublicoAlvo } from "./publicoAlvo";

export class Anuncio{
    public Id : number = 0;
    public CampanhaId : number = 0;
    public PublicoId : number = 0;
    public Publico : PublicoAlvo = new PublicoAlvo();
    public PlataformaAnuncio : PlataformaAnuncio = PlataformaAnuncio.Facebook;
    public CategoriaAnuncio : CategoriaAnuncio = CategoriaAnuncio.Conversao;
    public Alcance : number = 0;
    public Investimento : number =0;
    public Retorno : number =0;
    public Descricao : string | undefined;
    public Link : string | undefined;
    public LinkCurto : string | undefined;
    public Imagem : string = '';
    public DataFinal : Date | undefined;
    public DtFinal : string | undefined;
}