export class Email{
    public Id: number = 0;
    public EmpresaId : number = 0;

    public Titulo : string | undefined;
    public Destinatarios : string = "";
    public Body : string | undefined;
    public Remetente : string = "";
    public Entregues : number = 0;
    public Enviadas : number = 0;
    public Lidos : number = 0;
}