import { OportunidadeItem } from "./oportunidadeitem";

export class Oportunidade{
    public Id :number = 0;
    public LeadId: number = 0;

    public Itens: Array<OportunidadeItem> = new Array();
    public Desconto : number = 0;
    public Valor : number = 0;
    public ValorFinal : number = 0;
}