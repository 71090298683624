import { Component } from "@angular/core";
import { Usuario, ModeloProcesso, OutSaidaBaseWebApi, StatusRetorno } from "../models";
import { CRMService } from "../services/crmService.service";
import swal from "sweetalert2";
import { EventEmitterService, UtilService } from "../services";

@Component({
    moduleId: module.id,
    selector: 'smc',
    templateUrl: './smc.component.html',
    providers : [CRMService]
})
export class SMCComponent{

    public modelos : Array<ModeloProcesso> = new Array();
    public user : any = new Usuario();

    constructor(private crmService : CRMService){
    }

    ngOnInit(){
        this.user = UtilService.obterUsuario();
        this.obter();
    }
    
    obter(){
        EventEmitterService.get('onLoading').emit();
        this.crmService.obterModelosProcessos(this.user.EmpresaId)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => {
                if(ret.Status == StatusRetorno.SUCESSO)
                    this.modelos = ret.Entidade;
                EventEmitterService.get('offLoading').emit();
            },
            (error) => 
            {
                swal('Erro ao listar', '', 'error')
                EventEmitterService.get('offLoading').emit();
            }
        );
    }

    abrirModal(idModal : string){
        EventEmitterService.get('abrirModal').emit(idModal);
    }

    fecharModal(idModal : string){
        EventEmitterService.get('fecharModal').emit(idModal);
    }
}