import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { BuscarArgs } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class SyncService extends BaseService{

    obterProdutosComecaCom(empresaId : number, busca : string){
        return this.baseGetComParam(environment.backEstoqueUrl+'produto/ListarProdutosComecaCom', { empresaId : empresaId, busca : busca });
    };

    obterProdutoPorId(produtoId : number){
        return this.baseGetComParam(environment.backEstoqueUrl+'produto/ObterProduto', { produtoId : produtoId });
    };

    obterIP(){
        return this.baseGet(environment.baseUrl+'dadosGerais/ObterIp');
    }

    autenticarPorSessao(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrl+'usuario/autenticarPorSessao', pArgs);
    };

    verificaSessao(args : BuscarArgs){
        return this.basePost(environment.baseUrl+'usuario/VerificaSessao', args);
    };
}