import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoadingModule } from 'ngx-loading';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import { CKEditorModule } from 'ngx-ckeditor';
import { AppComponent } from './app.component';
import { MenuBarComponent } from './menubar/menubar.component';
import { AppRoutingModule } from './app.routing';
import { CookieModule } from 'ngx-cookie';
import { MyCurrencyFormatterDirective, HoraFormatterDirective } from './diretivas';
import { MyCurrencyPipe, HoraPipe } from './pipe';
import { SyncService } from './services/syncService.service';
import { PersonaComponent } from './persona/persona.component';
import { CRMService, PerfilService } from './services';
import { SMCComponent } from './smc/smc.component';
import { SMCCadastroComponent } from './smc/cadastrosmc.component';
import { ListaEmailComponent } from './listaEmail/listaEmail.component';
import { AutoCompleteModule } from "primeng/autocomplete";

@NgModule({
  declarations: [
    AppComponent,
    MenuBarComponent,
    MyCurrencyFormatterDirective,
    MyCurrencyPipe,
    HoraFormatterDirective,
    HoraPipe,
    PersonaComponent,
    SMCComponent,
    SMCCadastroComponent,
    ListaEmailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    LoadingModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    CalendarModule,
    InputSwitchModule,
    CKEditorModule,
    AutoCompleteModule
  ],
  providers: [MyCurrencyPipe,HoraPipe, SyncService, CRMService, PerfilService],
  bootstrap: [AppComponent]
})
export class AppModule { }
