import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { Recurso, Usuario } from '../models';

export class UtilService{

    static getParameterUrlByName(name : string, url = window.location.href) : string | null {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[;?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    static ConverterDouble(dado : any){
        var retorno = dado == undefined ? 0 : dado;
        if(retorno == "")
            retorno = 0;
        if(typeof retorno == 'string')
            retorno = parseFloat(retorno);
        return retorno;
    }

    static EncryptografarAES(texto : string) : string{
        var key = CryptoJS.enc.Utf8.parse('JDS438FDSSJHLWEQ'); 
        var iv = CryptoJS.enc.Utf8.parse('679FDM329IFD23HJ'); 
        var retorno = CryptoJS.AES.encrypt(texto, key, {
                keySize: 128,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return retorno.toString();
    }

    static DecryptografarAES(texto : string) : string{
        var key = CryptoJS.enc.Utf8.parse('JDS438FDSSJHLWEQ'); 
        var iv = CryptoJS.enc.Utf8.parse('679FDM329IFD23HJ'); 
        var retorno = CryptoJS.AES.decrypt(texto, key, {
                keySize: 128,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return retorno.toString(CryptoJS.enc.Utf8);
    }

    static removerToken(){
        return localStorage.removeItem(environment.LocalStorage.C_LS_TOKENAUTH);
    }

    static obterUsuario() : Usuario{
        if((localStorage.getItem(environment.LocalStorage.C_LS_USUARIO) !== null) && (localStorage.getItem(environment.LocalStorage.C_LS_USUARIO) !== undefined) && 
        (localStorage.getItem(environment.LocalStorage.C_LS_USUARIO) !== "")){
            let usuarioEncryptado = localStorage.getItem(environment.LocalStorage.C_LS_USUARIO);
            if(usuarioEncryptado !== null){
                let usuario = this.DecryptografarAES(usuarioEncryptado);
                return JSON.parse(usuario);
            }

            return new Usuario();
        }
        return new Usuario();
    }

    static setarUsuario(usuario : Usuario){
        if((usuario !== null) && (usuario !== undefined)){
            let usuarioEncryptado = this.EncryptografarAES(JSON.stringify(usuario));
            localStorage.setItem(environment.LocalStorage.C_LS_USUARIO, usuarioEncryptado);
        }
    }

    static removerUsuario(){
        localStorage.removeItem(environment.LocalStorage.C_LS_USUARIO);
    }

    static obterRecursos() : Array<Recurso>{
        if((localStorage.getItem(environment.LocalStorage.C_LS_RECURSOS) !== null) && (localStorage.getItem(environment.LocalStorage.C_LS_RECURSOS) !== undefined) &&
            (localStorage.getItem(environment.LocalStorage.C_LS_RECURSOS) !== "")){
            let recursosEncryptado = localStorage.getItem(environment.LocalStorage.C_LS_RECURSOS);

            if(recursosEncryptado !== null){
                let recursos = this.DecryptografarAES(recursosEncryptado);
                return JSON.parse(recursos);
            }
            return new Array();
        }
        return new Array();
    }

    static setarRecursos(recursos : Array<Recurso>){
        if((recursos !== null) && (recursos !== undefined)){
            let recursosEncryptado = this.EncryptografarAES(JSON.stringify(recursos));
            localStorage.setItem(environment.LocalStorage.C_LS_RECURSOS, recursosEncryptado);
        }
    }

    static removerRecursos(){
        localStorage.removeItem(environment.LocalStorage.C_LS_RECURSOS);
    }
}