import { Lead } from "./lead";
import { Produto } from "./produto";
import { ProdutoXTamanho } from "./produtoxtamanho";

export class OportunidadeItem{
    public Id : number = 0;
    public OportunidadeId : number = 0;

    public ProdutoId : number = 0;
    public Produto : Produto = new Produto();

    public Quantidade : number = 0;
    public ValorUnitario : number = 0;
    public Desconto : number = 0;
    public SubTotal : number = 0;
    public Observacao : string | undefined;
    public Tamanho : ProdutoXTamanho = new ProdutoXTamanho();
    public TamanhoId : number = 0;
}