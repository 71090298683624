import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Lead, AcompanhamentoLead, CampanhaAnuncio, Persona, JornadaCompra, FunilVendas, ModeloProcesso, ListaEmail, BuscarArgs } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class CRMService extends BaseService{

    obterPersonas(empresaId : number){
        return this.baseGetComParam(environment.baseUrlCRM+'api/CRMApi/ListarPersonas', {empresaId : empresaId});
    }

    salvarPersona(persona : Persona){
        return this.basePost(environment.baseUrlCRM+'api/CRMApi/SalvarPersona', persona);
    }

    obterJornadasCompra(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'jornada/ListarJornadasCompra', pArgs);
    }

    salvarJornada(jornada : JornadaCompra){
        return this.basePost(environment.baseUrlCRM+'jornada/SalvarJornada', jornada);
    }

    obterModelosProcessos(empresaId : number){
        return this.baseGetComParam(environment.baseUrlCRM+'modeloProcesso/ListarModelos', {empresaId : empresaId});
    }

    obterModeloProcesso(modeloId : number){
        return this.baseGetComParam(environment.baseUrlCRM+'modeloProcesso/ObterModeloProcesso', {modeloId : modeloId});
    }

    salvarModeloProcesso(modelo : ModeloProcesso){
        return this.basePost(environment.baseUrlCRM+'modeloProcesso/SalvarModeloProcesso', modelo);
    }

    obterListaEmail(empresaId : number){
        return this.baseGetComParam(environment.baseUrlCRM+'listaEmail/ListarEmails', {empresaId : empresaId});
    }
    obterListaEmailContem(empresaId : number, busca : string){
        return this.baseGetComParam(environment.baseUrlCRM+'listaEmail/ListarContatosContem', {empresaId : empresaId, busca : busca});
    }

    salvarListaEmail(email : ListaEmail){
        return this.basePost(environment.baseUrlCRM+'listaEmail/SalvarEmail', email);
    }
}