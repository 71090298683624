import { Component } from "@angular/core";
import { Usuario, ListaEmail, OutSaidaBaseWebApi, StatusRetorno, EmailXListaEmail } from "../models";
import { CRMService } from "../services/crmService.service";
import swal from "sweetalert2";
import { PublicoAlvo } from "../models/publicoAlvo";
import { EventEmitterService, PublicoAlvoService, UtilService } from "../services";
import { Frequencia } from "../models/frequencia";

@Component({
    moduleId: module.id,
    selector: 'listaEmail',
    templateUrl: './listaEmail.component.html',
    styleUrls: ['./listaEmail.component.css'],
    providers : [CRMService, PublicoAlvoService]
})
export class ListaEmailComponent{
    
    public lista : ListaEmail = new ListaEmail();
    public emails : Array<ListaEmail> = new Array();
    public email : EmailXListaEmail = new EmailXListaEmail();
    public frequencias : Array<any> = new Array();
    public segmentos : Array<any> = new Array();
    public user : any = new Usuario();
    public publicos : Array<PublicoAlvo> = new Array();

    constructor(private crmService : CRMService, private publicoServico : PublicoAlvoService){
    }

    ngOnInit(){
        this.user = UtilService.obterUsuario();
        this.obter();
        this.obterArrays();
        this.montarListasEnums();
    }

    obter(){
        EventEmitterService.get('onLoading').emit();
        this.crmService.obterListaEmail(this.user.EmpresaId)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => 
            {
                if(ret.Status == StatusRetorno.SUCESSO)
                    this.emails = ret.Entidade;
                EventEmitterService.get('offLoading').emit();
            },
            (error) =>{
                swal('Erro ao Listar', '', 'error')
                EventEmitterService.get('offLoading').emit()
            } 
        );
    }

    obterArrays(){
        EventEmitterService.get('onLoading').emit();
        this.publicoServico.obterPublicos(this.user.EmpresaId)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => 
            {
                if(ret.Status == StatusRetorno.SUCESSO)
                    this.publicos = ret.Entidade
                EventEmitterService.get('offLoading').emit();
            },
            (error) =>{
                swal('Erro ao Listar', '', 'error')
                EventEmitterService.get('offLoading').emit()
            }
        );
    }

    editar(cont : ListaEmail){
        this.lista = cont;
    }

    salvar(botao : any){
        EventEmitterService.get('onLoading').emit();
        this.lista.EmpresaId = this.user.EmpresaId;
        this.crmService.salvarListaEmail(this.lista)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => {
                if(ret.Status == StatusRetorno.SUCESSO)
                {
                    swal('Email Salvo Com Sucesso', 'Contato : '+ this.lista.Nome, 'success')
                    this.lista = ret.Entidade;
                    this.obter();
                }
                else
                    swal('Erro ao salvar email', ret.MsgErro, 'error')
                botao.click();
                EventEmitterService.get('offLoading').emit();
            },
            (error) => {
                EventEmitterService.get('offLoading').emit()
                swal('Erro ao Salvar', error.statusText, 'error')
            }
        );
    }
    
    novo(){
        this.lista = new ListaEmail();
    }

    excluirEmail(idx : number){
        this.lista.Emails.splice(idx, 1);
    }

    adicionarEmail(){
        if((this.email.Nome === undefined) || (this.email.Nome === "")){
            swal("", "Favor informar o Nome", 'error');
            return;
        }

        if((this.email.Email === undefined) || (this.email.Email === "")){
            swal("", "Favor informar o Email", 'error');
            return;
        }

        this.lista.Emails.push(this.email);
        this.email = new EmailXListaEmail();
    }

    montarListasEnums(){
        this.frequencias = new Array();
        this.segmentos = new Array();
        this.frequencias.push({key: 'Diario', value : 0 });
        this.frequencias.push({key: 'Tres_Dias', value : 1 });
        this.frequencias.push({key: 'Semanal', value : 2 });
        this.frequencias.push({key: 'Quinzenal', value : 3 });
        this.frequencias.push({key: 'Mensal', value : 4 });
        this.segmentos.push({key : 'Varejo', value: 1 });
        this.segmentos.push({key : 'Atacado e distribuidoras', value: 2 });
        this.segmentos.push({key : 'Manufatura', value: 3 });
        this.segmentos.push({key : 'Servicos', value: 4 });
        this.segmentos.push({key : 'Bares e restaurantes', value: 5 });
        this.segmentos.push({key : 'Outro', value: 6 });
        this.segmentos.push({key : 'Todos', value: 7 });
        this.segmentos.push({key : 'Varejo Roupas', value: 8 });
        this.segmentos.push({key : 'Aluguel', value: 9 });
    }

    enumFrequenciaString(frequencia: number){
        return Frequencia[frequencia];
    }

    abrirModal(idModal : string){
        EventEmitterService.get('abrirModal').emit(idModal);
    }

    fecharModal(idModal : string){
        EventEmitterService.get('fecharModal').emit(idModal);
    }
}