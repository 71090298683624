import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, TemplateMensagem } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class TemplateMensagemServico extends BaseService{
    obterTodos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'templatemsg/obterTodos', pArgs);
    }
    salvar(pTemplate : TemplateMensagem){
        return this.basePost(environment.baseUrlCRM+'templatemsg/salvar', pTemplate);
    }
}