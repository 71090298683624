export const environment = {
  production: true,
  baseUrl : 'https://back.gestaooutlet.com/',
  backEstoqueUrl : 'https://backestoque.gestaooutlet.com/',
  baseUrlCRM : 'https://backCrm.gestaooutlet.com/',
  urlPDV : 'https://checkout.gestaooutlet.com/#/',
  urlInicial : 'https://app.gestaooutlet.com/#/',
  urlFinanceiro : 'https://financeiro.gestaooutlet.com/#/',
  urlEstoque : 'https://estoque.gestaooutlet.com/#/',
  urlCRM : 'https://crm.gestaooutlet.com/#/',
  urlRestaurante : 'https://restaurante.gestaooutlet.com/#/',
  urlRelatorios : 'https://relatorios.gestaooutlet.com/#/',
  urlCMS: 'https://cms.gestaooutlet.com/#/',
  
  LocalStorage : {
    C_LS_TOKENAUTH: 'TokenAuthGO',
    C_LS_USUARIO : 'usuarioGO',
    C_LS_RECURSOS: 'recursosUserGO'
  }
};
