import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { PersonaComponent } from './persona/persona.component';
import { SMCComponent } from './smc/smc.component';
import { SMCCadastroComponent } from './smc/cadastrosmc.component';
import { ListaEmailComponent } from './listaEmail/listaEmail.component';

const appRoutes: Routes = [
    { path: 'contato', loadChildren: () => import("./contato/contato.module").then(m => m.ContatoModule) },
    { path: 'publicoAlvo', loadChildren: () => import("./publicoAlvo/publicoAlvo.module").then(m => m.PublicoAlvoModule) },
    { path: 'lead', loadChildren: () => import("./lead/lead.module").then(m => m.LeadModule) },
    { path: 'campanhaAnuncio', loadChildren: () => import("./campanha/campanha.module").then(m => m.CampanhaModule) },
    { path: 'persona', component: PersonaComponent },
    { path: 'jornadaCompra', loadChildren: () => import("./jornadacompra/jornadacompra.module").then(m => m.JornadaCompraModule) },
    { path: 'funilVendas', loadChildren: () => import("./funilvendas/funilvendas.module").then(m => m.FunilVendasModule) },
    { path: 'modelo', component: SMCComponent },
    { path: 'modeloCadastro', component: SMCCadastroComponent },
    { path: 'listaemail', component: ListaEmailComponent },
    { path: 'enviaremail', loadChildren: () => import("./email/enviarEmail.module").then(m => m.EnviarEmailModule) },
    { path: 'templatemensagem', loadChildren: () => import("./templatemensagem/templatemensagem.module").then(m => m.TemplateMensagemModule) },
    { path: 'fluxocadencia', loadChildren: () => import("./fluxocadencia/fluxocadencia.module").then(m => m.FluxoCadenciaModule) },
    { path: "historicocompras", loadChildren: () => import("./historicocompras/historicocompras.module").then(m => m.HistoricoComprasModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }