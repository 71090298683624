import {Injectable} from '@angular/core';

@Injectable()
export class GuardService{

    static ConverterDouble(dado : any){
        var retorno = dado == undefined ? 0 : dado;
        if(retorno == "")
            retorno = 0;
        if(typeof retorno == 'string')
            retorno = parseFloat(retorno);
        return retorno;
    }

}