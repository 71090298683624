import { ClassificacaoFinanceira } from "./ClassificacaoFinanceira";
import { ModeloBase } from "./modelobase";
import { Segmento } from "./segmento";
import { Subsegmento } from "./subsegmento";

export class Contato extends ModeloBase{
    public NomeEmpresa : string | undefined;
    public Nome : string | undefined;
    public Email : string | undefined;
    public Telefone : string | undefined;
    public Site : string | undefined;
    public ClassificacaoFinanceira : ClassificacaoFinanceira = ClassificacaoFinanceira.C;
    public PublicoAlvoId : number = 0;
    public Segmento : Segmento = Segmento.VarejoRoupas;
    public Subsegmento : Subsegmento = Subsegmento.VarejoRoupasMasculinas;
    public ClienteId : number = 0;
    public EmpresaId : number = 0;
    public Caracteristicas : string = "";
    public Plataforma : string | undefined;
}