import { Contato } from "./contato";
import { SituacaoLead } from "./situacaolead";
import { PlataformaAnuncio } from "./plataformaAnuncio";
import { AcompanhamentoLead } from "./acompanhamentoLead";
import { Oportunidade } from "./oportunidade";
import { ModeloBase } from "./modelobase";

export class Lead extends ModeloBase{
    public ContatoId : number = 0;
    public Contato : Contato = new Contato();
    public FunilId : number = 0;
    public EtapaFunilId : number = 0;
    public EmpresaId : number = 0;
    public AnuncioId : number = 0;
    public FluxoId : number | undefined;
    public Situacao : SituacaoLead = SituacaoLead.Iniciado;
    public PlataformaAnuncio : PlataformaAnuncio = PlataformaAnuncio.Facebook;
    public Observacao : string | undefined;
    public Acompanhamentos : Array<AcompanhamentoLead> = new Array();
    public Oportunidades: Array<Oportunidade> = new Array();
    public LigacoesFeitas : number = 0;
    public EmailsEnviados : number = 0;
    public FollowUps : number = 0;
    public Selecionado :boolean = false;
    public MotivoDescarte : string | undefined;
}