import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { HoraPipe } from "../pipe";

@Directive({ selector: "[horaFormatter]" })
export class HoraFormatterDirective implements OnInit {

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef,
        private pipe: HoraPipe){
            this.el = this.elementRef.nativeElement;
        }

    ngOnInit() {
        this.el.value = this.pipe.transform(this.el.value);
      }

    @HostListener("change", ["$event.target.value"])
    onChange(value : any) {
        this.el.value = this.pipe.transform(value);
    }
    
    @HostListener("keyup", ["$event.target.value"])
    onKeyup(value : any) {
        debugger
        this.el.value = this.pipe.transform(value);
    }
}