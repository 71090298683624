export enum PlataformaAnuncio{
    Facebook = 0,
    Instagram = 1,
    Twitter = 2,
    Snapchat = 3,
    Google = 4,
    Panfleto = 5,
    Radio = 6,
    Televisao = 7,
    Whattsapp = 8,
    Outros = 9,
    Email = 10
}