import { Perfil } from "./perfil";

export class Usuario{
    public Id : number = 0;
    public Nome : string | undefined;
    public Login : string | undefined;
    public Email : string | undefined;
    public Cpf : string | undefined;
    public Senha : string | undefined;
    public PerfilId : number = 0;
    public EmpresaId : number = 0;
    public Perfil : Perfil = new Perfil();
    public Ip : string | undefined;
    public ProdutorId : number = 0;
}