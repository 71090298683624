import { ModeloBase } from "./modelobase";

export class TemplateMensagem extends ModeloBase
{
    public EmpresaId : number = 0;
    public Descricao : string | undefined;
    public Assunto : string | undefined;
    public MensagemBase64 : string = "";
    public TipoTemplateMensagem : TipoTemplateMensagem = TipoTemplateMensagem.SMS;
}

export enum TipoTemplateMensagem 
{ 
    SMS = 0,
    Email = 1,
    WhatsApp = 2
}