export class OutSaidaBaseWebApi{
    public CodErro : number = 0;
    public MsgErro : string | undefined;
    public Status : StatusRetorno = StatusRetorno.SUCESSO;
    public Retorno : string | undefined;
    public Entidade: any;
}

export enum StatusRetorno
{
    SUCESSO = 0,
    ERRO = 1,
    ERROHTTP = 2
}