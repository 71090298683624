import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, InEnviarEmail } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class EmailService extends BaseService{
    enviarEmail(email : InEnviarEmail){
        return this.basePost(environment.baseUrlCRM+'Email/EnviarEmail', email);
    }

    selecionarDestinatariosPorPublicoAlvo(args : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'Email/BuscarEmailPorPublicoAlvo', args);
    }
}