import { Component } from "@angular/core";
import { Usuario, Persona, OutSaidaBaseWebApi, StatusRetorno } from "../models";
import { EventEmitterService, UtilService } from "../services";
import { CRMService } from "../services/crmService.service";
import swal from "sweetalert2";

@Component({
    moduleId: module.id,
    selector: 'persona',
    templateUrl: './persona.component.html',
    providers : [CRMService]
})
export class PersonaComponent{
    
    public user : any = new Usuario();
    public personas : Array<Persona> = new Array();
    public persona : Persona = new Persona();
    public segmentos: Array<any> = new Array();

    constructor(private crmService: CRMService){
    }

    ngOnInit(){
        this.user = UtilService.obterUsuario();
        this.obter();
        this.montarListasEnums();
    }

    obter(){
        EventEmitterService.get('onLoading').emit();
        this.crmService.obterPersonas(this.user.EmpresaId)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => 
            {
                if(ret.Status == StatusRetorno.SUCESSO)
                    this.personas = ret.Entidade;
                EventEmitterService.get('offLoading').emit();
            },
            (error : any) => 
            {
                swal('Erro ao Listar', '', 'error')
                EventEmitterService.get('offLoading').emit();
            }
        );
    }

    editar(person : Persona){
        this.persona = person;
        this.abrirModal("modalCadastro")
    }

    salvar(){
        EventEmitterService.get('onLoading').emit();
        this.persona.EmpresaId = this.user.EmpresaId;
        this.crmService.salvarPersona(this.persona)
        .subscribe(
            (ret : OutSaidaBaseWebApi) => {
                if(ret.Status == StatusRetorno.SUCESSO)
                {
                    swal('Persona Salvo Com Sucesso', 'Persona : '+ this.persona.Nome, 'success')
                    this.persona = ret.Entidade;
                    this.obter();
                    this.fecharModal("modalCadastro")
                }
                else
                    swal('Erro ao salvar persona', ret.MsgErro, 'error')
                
                EventEmitterService.get('offLoading').emit();
            },
            (error : any) => 
            {
                swal('Erro ao Salvar', error.statusText, 'error');
                EventEmitterService.get('offLoading').emit();
            }
        );
    }

    montarListasEnums(){
        this.segmentos = new Array();
        this.segmentos.push({key : 'Varejo', value: 1 });
        this.segmentos.push({key : 'Atacado e distribuidoras', value: 2 });
        this.segmentos.push({key : 'Manufatura', value: 3 });
        this.segmentos.push({key : 'Servicos', value: 4 });
        this.segmentos.push({key : 'Bares e restaurantes', value: 5 });
        this.segmentos.push({key : 'Outro', value: 6 });
        this.segmentos.push({key : 'Todos', value: 7 });
        this.segmentos.push({key : 'Varejo Roupas', value: 8 });
        this.segmentos.push({key : 'Aluguel', value: 9 });
    }

    novo(){
        this.persona = new Persona();
        this.abrirModal("modalCadastro");
    }

    abrirModal(idModal : string){
        EventEmitterService.get('abrirModal').emit(idModal);
    }

    fecharModal(idModal : string){
        EventEmitterService.get('fecharModal').emit(idModal);
    }
}