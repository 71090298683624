import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { BuscarArgs } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class ProdutoService extends BaseService{

    obterProdutos(empresaId : any){
        return this.baseGetComParam(environment.backEstoqueUrl+'produto/ListarProdutos', {empresaId : empresaId});
    };

    obterProdutosContem(pArgs : BuscarArgs){
        return this.basePost(environment.backEstoqueUrl+'produto/ListarProdutosComecaCom', pArgs);
    };

    obterProduto(produtoId : any){
        return this.baseGetComParam(environment.backEstoqueUrl+'produto/ObterProduto', {pProdutoId : produtoId});
    };

}