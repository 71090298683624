export class FluxoCadenciaItem
{
    public Id : number = 0;
    public FluxoCadenciaId : number = 0;
    public Dia : number = 1;
    public Periodo : TipoPeriodoDia = TipoPeriodoDia.MANHA;
    public Acao : TipoAcaoCadenciaItem = TipoAcaoCadenciaItem.LIGACAO;
    public TemplateId : number = 0;
}

export enum TipoPeriodoDia
{
    MANHA = 0,
    TARDE = 1
}

export enum TipoAcaoCadenciaItem
{
    LIGACAO = 0,
    EMAIL = 1,
    CURTIDALINKEDIN = 2,
    MSGLINKEDIN = 3,
    MSGINSTAGRAM = 4,
    WHATSAPP = 5
}