import { Segmento } from "./segmento";

export class Persona{
    public Id: number = 0;
    public EmpresaId : number = 0;
    public Nome : string | undefined;
    public Idade : number = 0;
    public Funcao : string | undefined;
    public FrequenciaSociais : string | undefined;
    public Segmento : Segmento = Segmento.VarejoRoupas;
    public Objetivos : string | undefined;
    public Dores : string | undefined;
    public Escolaridade : string | undefined;
    public TempoFuncao : number = 0;
    public NivelTecnologico : string | undefined;
    public Canais : string | undefined;
}