export enum Subsegmento{
    VarejoRoupasFemininas = 1,
    VarejoRoupasMasculinas = 2,
    VarejoRoupasModaPraia = 3,
    AluguelVeiculos = 4,
    DistribuidoraAlimentos = 5,
    VarejoVariedades = 6,    
    VarejoFarmacia = 7,
    VarejoEquipamentos = 8,
    VarejoPet = 9,
    VarejoLivraria = 10,
    VarejoMercearia = 11,
    Bar = 12,
    VarejoJoiasAcessorios = 13,
    VarejoFastFood = 14,
    VarejoAutoPecas = 15,
    VarejoMoveis = 16,
    VarejoEletronicos = 17,
    ServicosLimpeza = 18,
    ServicosMecanica = 19,
    ServicosGerais = 20,
    Aluguel_Noivas = 21,
    PETSHOP = 22,
    Varejo_Modas_Infantis = 23,
    Varejo_Calcados = 23
}