import { Tamanho } from "./tamanho";
import { Produto } from "./produto";

export class ProdutoXTamanho{
    public Id : number = 0;
    public TamanhoId : number = 0;
    public Tamanho : Tamanho = new Tamanho();

    public ProdutoId : number = 0;
    public Produto : Produto = new Produto();

    public ValorVenda : number = 0;
}