export class EtapaModelo{
    public Id : number = 0;
    public ModeloProcessoId : number = 0;
    public Descricao : string | undefined;
    public Objetivo : string | undefined;
    public Ferramentas : string | undefined;
    public Validacao : string | undefined;
    public TempoEstagnacao : string | undefined;
    public MotivosPerda : string | undefined;
    public TaxaConversao : string | undefined;
    public MetaAvanco : string | undefined;
}