import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, FunilVendas, InCarregarContatosPlanilha } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class FunilService extends BaseService{
    
    obterTodos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'funil/obterTodos', pArgs);
    }

    obterPorId(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'funil/obterPorId', pArgs);
    }

    salvar(funil : FunilVendas){
        return this.basePost(environment.baseUrlCRM+'funil/salvar', funil);
    }

    carregarContatosPlanilha(pInput : InCarregarContatosPlanilha){
        return this.basePost(environment.baseUrlCRM+'funil/carregarContatosPlanilha', pInput);
    }
}