import { Anuncio } from "./anuncio";

export class CampanhaAnuncio{
    public Id: number = 0;
    public Descricao : string | undefined;
    public EmpresaId : number = 0;
    public Investimento : number = 0;
    public Retorno : number = 0;
    public Periodo : number = 0;
    public Alcance : number = 0;
    public Anuncios : Array<Anuncio> = new Array();
}