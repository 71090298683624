import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BuscarArgs, FluxoCadencia } from "../models";
import { BaseService } from "./base.servico";

@Injectable()
export class FluxoCadenciaServico extends BaseService{
    obterTodos(pArgs : BuscarArgs){
        return this.basePost(environment.baseUrlCRM+'fluxocadencia/obterTodos', pArgs);
    }
    salvar(pTemplate : FluxoCadencia){
        return this.basePost(environment.baseUrlCRM+'fluxocadencia/salvar', pTemplate);
    }
}